// @flow

import { graphql } from 'gatsby';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import {
  Layout,
  MarkdownPs,
  Section,
  TabMenu,
  Text,
  MainNewsImage,
} from '../components';
import { Dimen } from '../constants';

import {
  FacebookShareButton,
  GooglePlusShareButton,
  TwitterShareButton,
} from 'react-share';

import { FacebookIcon, TwitterIcon, GooglePlusIcon } from 'react-share';

import type { NewsArticlePageTemplateQuery } from '../__generated__/NewsArticlePageTemplateQuery';

type Props = $ReadOnly<{|
  location: Location,
  data: NewsArticlePageTemplateQuery,
|}>;

const NewsArticle = ({ location, data: { article, prev, next } }: Props) => {
  if (!article) {
    throw Error('Not found');
  }

  const introductionHtml = article.introductionNode?.childMarkdownRemark?.html;
  const contentHtml = article.contentNode?.childMarkdownRemark?.html;

  return (
    <Layout>
      <TabMenu
        links={[
          {
            url: '/news/host',
            label: `View Host News`,
            highlight: false,
          },
          {
            url: '/news/guest',
            label: 'View Guest News',
            highlight: false,
          },
        ]}
      />
      <MainNewsImage
        article={article}
        prev={prev || null}
        next={next || null}
      />
      <Section>
        <StyledMainText>
          <Text tag="p" color="faintBlue" size={26} light>
            {article.publishDate} {article.guest && `• Guest News`}{' '}
            {article.host && `• Host News`}
          </Text>
          {article.introduction && (
            <MarkdownPs size={26} bold html={article.introduction} />
          )}
          {article.content && (
            <MarkdownPs
              size={26}
              light
              color="faintBlue"
              html={article.content}
            />
          )}
        </StyledMainText>
        <Text tag="p" size={26}>
          Share this:
        </Text>
        <StyledTwitterShareButton url={location.href} title={article.headline}>
          <TwitterIcon size={rem(Dimen.spacing)} round={true} />
        </StyledTwitterShareButton>
        <StyledFacebookShareButton url={location.href} quote={article.headline}>
          <FacebookIcon size={rem(Dimen.spacing)} round={true} />
        </StyledFacebookShareButton>
        <StyledGooglePlusShareButton url={location.href}>
          <GooglePlusIcon size={rem(Dimen.spacing)} round={true} />
        </StyledGooglePlusShareButton>
      </Section>
    </Layout>
  );
};
export default NewsArticle;

export const query = graphql`
  query NewsArticlePageTemplateQuery(
    $originalId: String!
    $prevOriginalId: String
    $nextOriginalId: String
  ) {
    article: datoCmsNewsArticle(originalId: { eq: $originalId }) {
      publishDate(formatString: "D MMMM YYYY")
      introduction
      content
      guest
      host
      ...MainNewsImage_article
    }
    prev: datoCmsNewsArticle(originalId: { eq: $prevOriginalId }) {
      headline
      originalId
    }
    next: datoCmsNewsArticle(originalId: { eq: $nextOriginalId }) {
      headline
      originalId
    }
  }
`;

const StyledMainText = styled('div')`
  padding: ${rem(Dimen.spacing)} 0;
`;

const StyledTwitterShareButton = styled(TwitterShareButton)`
  display: inline-block;
  margin-bottom: ${rem(Dimen.spacing)};
`;

const StyledFacebookShareButton = styled(FacebookShareButton)`
  padding: 0 ${rem(Dimen.spacing / 4)};
  margin-bottom: ${rem(Dimen.spacing)};
  display: inline-block;
`;

const StyledGooglePlusShareButton = styled(GooglePlusShareButton)`
  padding: 0;
  display: inline-block;
  margin-bottom: ${rem(Dimen.spacing)};
`;
